/* ####################### FORM CSS START ####################### */

.required:after {
    content: " *";
    color: red;
}

.lbl {
    margin-left: 10px !important;
    color: #637381;
    font-weight: "400" !important
}

.selectfield {
    display: block;
    padding: 15px;
    font-weight: 400;
    font-size: inherit;
    border-radius: 7px;
    background-color: white;
}

.selerr {
    color: #FF4842;
    line-height: 1.5 !important;
    font-size: 0.75rem !important;
    font-family: unset !important;
    text-align: left !important;
    margin-top: 3px !important;
    margin-right: 14px !important;
    margin-bottom: 0 !important;
    margin-left: 14px !important;
}

.ml10 {
    margin-left: 10px !important;
}

.seloptionfield {
    color: #637381;
    padding: 15px;
    font-weight: 400;
    font-size: inherit
}

/* ####################### TABLE CSS START ####################### */

.setmark {
    background-color: #e16363;
}

.setwarning {
    background-color: orange;
}


.highlight_cell {
    color: #2065d1;
}

.trashButton {
    color: red;
}

.stausButton {
    color: #2065d1;
}


/** Image css **/

.image-area {
    position: relative;
    width: 10%;
    background: #333;
}

.image-area img {
    max-width: 100%;
    height: auto;
}

.img_enlarge:hover {
    transform: scale(2);
    cursor: pointer;
}

.remove-image {
    display: none;
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 10em;
    padding: 2px 6px 3px;
    text-decoration: none;
    font: 700 21px/20px sans-serif;
    background: #555;
    border: 3px solid #fff;
    color: #FFF;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    -webkit-transition: background 0.5s;
    transition: background 0.5s;
}

.remove-image:hover {
    background: #E54E4E;
    padding: 3px 7px 5px;
    top: -11px;
    right: -11px;
}

.remove-image:active {
    background: #E54E4E;
    top: -10px;
    right: -11px;
}

.react-loader-container-main {
    left: 50% !important;
    top: 50% !important
}

.multi-drpdown {
    margin-bottom: 3%;
}

/* Customised Form Css end*/